import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { datadogLogs } from "@datadog/browser-logs";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ViewportProvider } from "./hooks/useViewPort";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import setupInterceptors from "./interceptor";
import ErrorBoundary from "./shared-components/ErrorBoundary";

datadogLogs.init({
  clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN ?? "",
  site: process.env.REACT_APP_DATADOG_SITE,
  service: process.env.REACT_APP_DATADOG_SERVICE,
  env: process.env.REACT_APP_DATADOG_ENV,
  forwardErrorsToLogs: true,
  forwardConsoleLogs: ['error'],
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
// Configure Store
setupInterceptors(store);
root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <BrowserRouter>
        <ViewportProvider>
          <App />
        </ViewportProvider>
      </BrowserRouter>
    </Provider>
  </ErrorBoundary>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
