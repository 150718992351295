import React, { useEffect, useState } from "react";
import _ from "lodash";
import CustomLoader from "./shared-components/Loader";
import PrivilegeNotFound from "./shared-components/PrivilegeNotFound";
import Layout from "./layout";
import { useDispatch, useSelector } from "react-redux";
import { StorCurrentLocation, currentLocationStorage } from "./common.actions";
import { azureAdFetchCode, cacheManager, clearAzureAdAuthCodeAction, extractValueFromUrl, isReactAppAuthorized, isTokenExistInCookies, routeNavigationUrl } from "./helpers/util-common";
import "bootstrap/dist/css/bootstrap.min.css";
import "astm-ux-design-system/dist/bundle.css";
import "./assets/themes/main.css";
import Alert from "./shared-components/Alert";
import { FeatureFlagProvider } from "./provider/flagFeature";
import loginActions from "./models/login/login.actions";
import commonActions, { initiateAzureRequestAction } from "./common.actions";
import { PAGE_TYPE, USER_PRIVILEGE } from "./models/navigation.models";
import AlertDissmissable from "./shared-components/AlertDissmissable";
import { logger, LOG_TYPE } from "./helpers/dataDog.logger";

interface Props {
  history?: any;
}

export const App: React.FC<Props> = ({ history }: Props) => {
  const dispatch = useDispatch();
  const authenticateByAzureAD = (callback: any) => {
    loginActions.authenticateByAzureADAction(dispatch, callback);
  };
  const authByCookiesToken = (inputDTO: any, callback: any) => {
    loginActions.authByCookiesTokenAction(dispatch, inputDTO, callback);
  };
  const initiateAzureRequest = (code: any, sessionState: any, authState: any, cb: any) => initiateAzureRequestAction(code, sessionState, authState, cb, dispatch);
  const checkSessionInfo = (callback: any) => {
    loginActions.checkSessionInfo(dispatch, callback);
  };

  const props = useSelector((state: any) => {
    return {
      showToastMessage: state.commonReducer.showToastMessage,
      loading: state.commonReducer.loading,
      content: _.get(state.i18nReducer.dictionaries[state.i18nReducer.currentLanguage], state.commonReducer.code),
      showErrorPopup: state.commonReducer.showErrorPopup,
      errorMessages: state.commonReducer.errorMessages,
      isShowConfirmationPopup: state.commonReducer.isShowConfirmationPopup,
      showAlertPopup: state.commonReducer.showAlertPopup,
      alertMessage: state.commonReducer.alertMessage,
      userPermission: state.commonReducer.userPermission,
      miniLoader: state.commonReducer.miniLoader,
      isSafariAutoFillInfoVisible: state.commonReducer.isSafariAutoFillInfoVisible,
      isDocumentInfo: state.commonReducer.isDocumentInfo,
      message: state.commonReducer.message,
      uniqueRequestId: state.commonReducer.uniqueRequestId,
      dismissibleMessage: state.commonReducer.dismissibleMessage,
      history: history,
      showSuccessToastMesage: state.commonReducer.showSuccessToastMesage,
      showDismissibleToastMessage: state.commonReducer.showDismissibleToastMessage,
    };
  });

  const [defaultNavigationUrl, setdefaultNavigationUrl] = useState("");
  const [isLoginByAzureAD, setisLoginByAzureAD] = useState(process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true");
  const [isUserHavePrivileges, setisUserHavePrivileges] = useState(false);
  const [validateUserSession, setValidateUserSession] = useState(0);
  const [hasServerSession, setHasServerSession] = useState(false);
  useEffect(() => {
    if (validateUserSession == 0) return;
    validateAzureCodeOnUrl();
  }, [validateUserSession]);
  useEffect(() => {
		logger("DMS app initialized!", {}, LOG_TYPE.info, "");
    checkSessionInfo((response: any) => {
      let keyStatus = false;
      if (response.success) {
        if (!response.data.hasDMSAppKey) {
          cacheManager.clearSession();
        } else {
          keyStatus = true;
        }
        setHasServerSession(keyStatus);
      }
      setValidateUserSession(1);
    });
  }, []);
  // Validate Azure AD request for AD-TOKEN
  const validateAzureCodeOnUrl = () => {
    //const { PAGE_TYPE, USER_PRIVILEGE } = useSelector;
    const locationObject = currentLocationStorage();
    let isLoginWithAD = isLoginByAzureAD;
    if (isLoginWithAD && !isReactAppAuthorized()) {
      if (isTokenExistInCookies()) {
        // RNE Application type Id, For get Redis Key.
        let applicationTypeId = 1;
        authByCookiesToken({ appId: applicationTypeId }, (isSuccess: any, code: any) => {
          if (isSuccess) {
            setHasServerSession(true);
            window.history.pushState({}, "", "/docupload");
            window.location.reload();
          } else if (!isSuccess && code === "1013") {
            // Call here for No Privilege Found
            setisUserHavePrivileges(true);
          } else {
            // Else clear session.
            window.location.assign(clearAzureAdAuthCodeAction());
          }
        });
      } else {
        let code = extractValueFromUrl(window.location.href, "code");
        let sessionState = extractValueFromUrl(window.location.href, "session_state");
        let authState = extractValueFromUrl(window.location.href, "state");

        if (code && sessionState && authState) {
          // TODO : Azure Init Call for create browser session.
          initiateAzureRequest(code, sessionState, authState, (isSuccessAzureInitRequest: any) => {
            if (isSuccessAzureInitRequest) {
              // TODO : Auth Code Login Call while get success azure init call.
              authenticateByAzureAD((isSuccessAzureAuthRequest: any, resCode: any) => {
                if (isSuccessAzureAuthRequest) {
                  setHasServerSession(true);
                  if (locationObject.isLocation) {
                    props.history.push(locationObject.location);
                    // history.push(locationObject.location);
                  } else {
                    StorCurrentLocation(dispatch, "/docupload");
                    window.history.pushState({}, "", "/docupload");
                    window.location.reload();
                    //window.history.pushState({}, '', "/");
                    //props.history.push(routeNavigationUrl(PAGE_TYPE.DOCUMENT_UPLOAD, USER_PRIVILEGE.VIEW));
                  }
                } else if (!isSuccessAzureAuthRequest && resCode === "1013") {
                  // Call here for No Privilege Found
                  setisUserHavePrivileges(true);
                } else {
                  // Else clear session.
                  window.location.assign(clearAzureAdAuthCodeAction());
                }
              });
            } else {
              // Call here for No Privilege Found
              setisUserHavePrivileges(true);
            }
          });
        } // Redirect for get token from Azure AD
        else {
          window.location.assign(azureAdFetchCode());
        }
      }
    }
  };

  return (
    <div data-testid="app-id">
      {(props.loading || props.miniLoader) && <CustomLoader />}
      {props.showSuccessToastMesage && <Alert content={props.content} message={props.message} />}
      <div className="dismiss-toaster-wrapper">
        {props.showToastMessage && <Alert content={props.content} message={props.message} uniqueRequestId={props.uniqueRequestId} />}
        {props.showDismissibleToastMessage && <AlertDissmissable content={props.content} message={props.dismissibleMessage} />}
      </div>

      {isUserHavePrivileges ? (
        <PrivilegeNotFound />
      ) : (
        hasServerSession && (
          <FeatureFlagProvider>
            <Layout></Layout>
          </FeatureFlagProvider>
        )
      )}
    </div>
  );
};

export default App;
