import { logger, LOG_TYPE } from "../../helpers/dataDog.logger";
import React from "react";

interface IProps {
    children: any;
}

class ErrorBoundary extends React.Component<IProps> {
    componentDidCatch = (error: any) => {
        logger(
            `Error Boundary: ${error}`,
            {},
            LOG_TYPE.error,
            error
        );
    }

    render() {
        return this.props.children;
    }
}

export default ErrorBoundary;
