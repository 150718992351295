import axios from 'axios';
import lodash from 'lodash';
import { logout, setCurrentLocationStorage, setMessage } from './common.actions';
import getApiUrl from './helpers/api-urls';
import * as utilCommon from './helpers/util-common';
import { cookieKeysEnum } from './models/common.models';
import { logger, LOG_TYPE } from './helpers/dataDog.logger';

// Exclude Internal Requests for bearer token
const excludeRequests = [
    getApiUrl('login', 'getAzureADRefreshToken'),
    getApiUrl('login', 'login'),
    getApiUrl('login', 'authenticateByAzureAD')
];

let isRefreshing = false;
let isLogoutRequest = false;
let tokenSubscribers: any = [];
const setupInterceptors = (store: any) => {

    // All Interceptor Request
    axios.interceptors.request.use((config: any) => {
        if (!config.url.includes('specbuilder.astm.org')) {
            let generateUUID = utilCommon.generateUUID();
            // Set app request token.
            setAuthorizationOnApp(config);
            // Set Unique UDID for each request.
            if (utilCommon.cacheManager.getItem('uniqueRequestIdForApis') === null) {
                utilCommon.cacheManager.setItem('uniqueRequestIdForApis', JSON.stringify({}))
            }
            let storageUUIDValues = JSON.parse(utilCommon.cacheManager.getItem('uniqueRequestIdForApis'));
            const newStorageUUIDValues = { ...storageUUIDValues };
            newStorageUUIDValues[config.url] = generateUUID;
            utilCommon.cacheManager.setItem('uniqueRequestIdForApis', JSON.stringify(newStorageUUIDValues));
            config.headers.uniquerequestid = generateUUID;
            // TODO : Check Hostname is localhost and set azure request header.
            if (utilCommon.isLocalhost()) {
                config.headers.AZURE_INIT_REQUEST = utilCommon.cookiesManager.getCookie(cookieKeysEnum.APP_SESSIONID, false)
            }

        }
        return config;
    });

    // All Interceptor Response
    axios.interceptors.response.use((response) => {
        if (response && response.data) {
            // Validate Data is in String Format
            if (lodash.isString(response.data)) {
                // Parse JSON for security
                let updateData = response.data.replace(')]}', "");
                response.data = JSON.parse(updateData);
            }
            let msgCode = lodash.get(response.data, 'message');
            // Pass code in auto logout function.
            autoLogoutOnInvalidCodes(msgCode, store);
        } else {
            logger(
                `API Response Error: ${response.config.url}`,
                {
                    url: response.config.url,
                    uniqueRequestId: response.config.headers.uniquerequestid,
                    requestedData: response.config.data,
                    responseData: response.data,
                    status: response.status,
                    statusText: response.statusText,
                },
                LOG_TYPE.error,
                {
                    name: `API Response Error: ${response.config.url}`
                }
            );
        }
        return response;
    }, (error) => {
        const { config, response: { status, data: { message } } } = error;
        const originalRequest = config;
        logger(
            `API Server Request Error: ${error.config.url}`,
            {
                url: error.config.url,
                uniqueRequestId: error.config.headers.uniquerequestid,
                requestedData: error.config.data
            },
            LOG_TYPE.error,
            {
                name: `API Server Request Error: ${error.config.url}`,
                message: error.code
            }
        );
        if (status === 401 && process.env.REACT_APP_LOGIN_BY_AZURE_AD == "true") {
            let email = utilCommon.getCurrentUserEmail();
            // Validate if refresh token call has gone at once.
            if (!isRefreshing) {
                // TODO : Force Logout while no valid email id found.
                if (!email) {
                    autoLogoutOnInvalidCodes(status, store);
                    return false;
                }

                // If code will reach here,then new token will be updated.
                isRefreshing = true;
                getAzureADRefreshTokenAction({ "email": email }, (tokenResult: any) => {
                    if (tokenResult.status) {
                        isRefreshing = false;
                        onRrefreshedHandler(tokenResult.accessToken);
                        tokenSubscribers = [];
                    } else {
                        // Validate Azure Ad Logout form app.
                        autoLogoutOnInvalidCodes(message, store)
                    }
                });
            }

            // TODO : Auto-Logout while refresh token call failer [401:Status, 1005:Message] => No Redis Found in Redis Cache.
            if (config.url === getApiUrl('login', 'getAzureADRefreshToken') && Number(message) === 1005) {
                autoLogoutOnInvalidCodes(message, store);
                return false;
            }
            // TODO : Re-Request  for All failer calls.
            return new Promise((resolve, reject) => {
                subscribeToken((token: any) => {
                    // Replace the expired token and retry
                    originalRequest.headers.Authorization = 'Bearer ' + token;
                    resolve(axios(originalRequest));
                });
            });
        }
        return Promise.reject(error);
    });

};

// Refresh token call While got 401 (Un-Authorized) Status.
export const getAzureADRefreshTokenAction = (inputDTO: any, callback: any) => {
    const url = getApiUrl('login', 'getAzureADRefreshToken');
    axios.post(url, inputDTO).then((response) => {
        if (lodash.get(response.data, 'success')) {
            callback({ status: true, accessToken: response.data.accessToken })
            utilCommon.setLoginDataToCacheManager(response, 'REFRESH'); // Add Refresh Token Details in LS.
        } else {
            callback({ status: false, accessToken: null })
            utilCommon.setLoginDataToCacheManager(response, 'DELETE'); // For delete Detials in LS.
        }

    })
};

// Cached all Http calls which one failed
export const subscribeToken = (cb: any) => {
    tokenSubscribers.push(cb);
}

// Refresh handler while all http calls failed
export const onRrefreshedHandler = (token: any) => {
    tokenSubscribers.map((cb: any) => cb(token));
}

// Auto Logout while found unauthorized request.
export const autoLogoutOnInvalidCodes = (code: any, store: any) => {
    // In case of Auto logout while getting code (1005) from server.

    if (code && Number(code) === 1005 && !isLogoutRequest) {
        isLogoutRequest = true;
        if (window.location.pathname != '/') {
            store.dispatch(setMessage(true, '401'));
            logout(store.dispatch);
            // Logout If token Unauthorized
            utilCommon.cacheManager.clearSession();
            utilCommon.cookiesManager.removeCookie(cookieKeysEnum.APP_SESSIONID);
            setCurrentLocationStorage()
            setTimeout(() => { window.location.assign(utilCommon.clearAzureAdAuthCodeAction()) }, 100)
        }
    }
}

// Custom Logout While Get 401 on Error.
export const customLoginClearCache = (status: any, store: any) => {
    if (process.env.REACT_APP_LOGIN_BY_AZURE_AD == "false" && status === 401) {
        store.dispatch(setMessage(true, status));
        // Logout If token Unauthorized
        utilCommon.cacheManager.clearSession();
        if (window.location.pathname != '/') {
            store.dispatch(logout(store.dispatch));
        }
        return false;
    }
}


// Set Authorization token in all request.
export const setAuthorizationOnApp = (config: any) => {
    let token = null;
    if (utilCommon.isReactAppAuthorized() && excludeRequests.indexOf(config.url) === -1) {
        token = utilCommon.cacheManager.getItem(cookieKeysEnum.accessToken, false);
        const stringIndex = config.url.split("v1")
        utilCommon.openApiPathArray.indexOf(stringIndex[1])
        if (utilCommon.openApiPathArray.indexOf(stringIndex[1]) === -1) {
            config.headers.Authorization = 'Bearer ' + token;
        }
        config.headers['Is-Test-Mode'] = isTestUserLogin();
    }
    else if (!utilCommon.isReactAppAuthorized() && config.url.includes(utilCommon.openApiPathArray)) {
        config.headers['Is-Test-Mode'] = isTestUserLogin();
    }
}

export const isTestUserLogin = () => {
    if (utilCommon.cacheManager.getItem('userDetail')) {
        return JSON.parse(utilCommon.cacheManager.getItem('userDetail')).isTestRecord === true;
    }
    return false;
}

export default setupInterceptors;


