import React, { ReactNode, useContext, useEffect, useState } from "react";
import utilCommon, { cacheManager, isReactAppAuthorized, getFormattedDate, validateNavigationFromUrl, downloadFile, timeLocal, utcDateTimeSecondToLocalDateTime } from "../helpers/util-common";
import HeaderMenu from "../shared-components/Header/header";
import { useDispatch, useSelector } from "react-redux";
import { datadogLogs } from "@datadog/browser-logs";
import Breadcrumb from "../shared-components/breadcrumb";
import LeftMenu from "../shared-components/left-menu/leftmenu";
import Navigation from "../navigation/navigation";
import "./_layoutScreen.scss";
import DocumentInfo from "../shared-components/Accordion";
import { fileTypeSVGFormat, getDocumentCategoryId, categoryIdForDocuments, meetingStatusList, BallotStatus } from "../models/common.models";
import lodash, { isEmpty } from "lodash";
import { Dropdown } from "react-bootstrap";
import commonActions, { getPermissionOnPageAction, setManualZipStatusInfo } from "../common.actions"; //
import { handleDocumentInfoClickAction, getDocumentInfoByIdAction, updateDocumentInfoAction, getDownloadDocumentFileUrlAction, setFlagForBallotNavigation, getBallotFolderZipFileStatus, ballotFixedHeaderAction } from "../redux/action/alldocuments";
import { useNavigate, useLocation } from "react-router-dom";
import { HIDE_NOT_FOUND_PAGE, SHOW_NOT_FOUND_PAGE } from "../action-type";
import AccessDenied from "../shared-components/access-denied/AccessDenied";
import { DownloadDocumentFileResponse } from "../pages/alldocuments/interface";
import { LEFT_NAVIGATION_PAGE_URL } from "../models/navigation.models";
import InvalidUrl from "../shared-components/invalid-url/InvalidUrl";
import CustomTooltip from "../components/atoms/CustomTooltip";
import { publishBallotFolder } from "../redux/action/documentUpload";
import { FeatureFlag } from "../provider/flagFeature";

interface UpdateDocumentRequestData {
  DocumentDataId: string;
  DocumentCategoryId: string;
  CreatedBy: string;
  DocumentStatusId: string | null;
  displaySuccessMessage: string;
  GroupIdentifier?: string;
  MeetingDate?: string;
}

export const Layout = () => {
  const dispatch = useDispatch();
  const rightRef = React.useRef<HTMLDivElement>(null);
  const [sidebarClass, setSidebarClass] = useState(false);
  const [documentInfo, setDocumentInfo] = useState<any>({});
  const [showMore, setShowMore] = useState(false);
  const [isAuth, setIsAuth] = useState(true);
  const [documentStatus, setDocumentStatus] = useState("");
  const [currentPath, setCurrentPath] = useState("/");
  const [showAccessDenied, setShowAccessDenied] = useState(false);

  const history = useLocation();
  const featureData: any = useContext(FeatureFlag)?.FlagFeaturesData;
  const handleScroll = () => {
    const current = rightRef.current;
    if (current!.scrollTop >= 193) {
      ballotFixedHeaderAction(dispatch, true);
    } else {
      ballotFixedHeaderAction(dispatch, false);
    }
  };

  const props = useSelector((state: any) => {
    return {
      isDocumentInfo: state.allDocumentsReducer.isDocumentInfo,
      DocumentDataId: state.allDocumentsReducer.allDocumentsClickedId,
      displayFileName: state.allDocumentsReducer.displayFileName,
      currentLocation: state.commonReducer.currentRequest,
      hasUpdateAccess: state.allDocumentsReducer.hasUpdateAccess,
      documentStatusByCategory: state.commonReducer.documentStatusByCategory,
      showNotFoundPage: state.commonReducer.showNotFoundPage,
      ballotFlag: state.commonReducer.ballotFlag,
      isEnableBallotManualPublish: state.commonReducer.isEnableBallotManualPublish,
      ballotManualZipInfo: state.commonReducer.ballotManualZipInfo,
      litigationModal: state.commonReducer.litigationModal,
    };
  });

  const checkGetPermissionRequestFromUrl = (requestUrl: any) => {
    let isAuthorized = false;
    // Condition for Allow Default Route
    if (requestUrl && requestUrl.routeUrl === "/dashboard") {
      isAuthorized = true;
    } else if (requestUrl && requestUrl.routeUrl == LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD && !featureData?.DmsAppFeatureEnableLitigationHold) {
      isAuthorized = false;
    } else {
      // Rest Validate condition for Route allow or not
      let isPageAuthorized = utilCommon.filterPermissionUrl(requestUrl, requestUrl.oslMethod);
      isAuthorized = utilCommon.allowPermissionForAuth(isPageAuthorized);
    }
    setIsAuth(isAuthorized);
  };

  const validateBreadcrumbsUrls = () => {
    const currentPath = history.pathname;

    // Get URL Details
    if (currentPath == "/" && isReactAppAuthorized()) {
      window.history.pushState({}, "", "/docupload");
      setCurrentPath("/docupload");
      window.location.reload();
    }
    let urlDetails = validateNavigationFromUrl(currentPath);
    if (!urlDetails) {
      dispatch({ type: SHOW_NOT_FOUND_PAGE }); // IF Routes Not Matched
    } else {
      dispatch({ type: HIDE_NOT_FOUND_PAGE });
    }
    // IF Routes Matched
    if (lodash(urlDetails).get("selectedSingleResult", null) && lodash(urlDetails).get("selectedSingleResult").length > 0) {
      let navUrlResult = lodash(urlDetails).get("selectedSingleResult")[0];
      checkGetPermissionRequestFromUrl(navUrlResult);
      dispatch(getPermissionOnPageAction(navUrlResult, lodash(urlDetails).get("selectedFullResult", null)));
      if (currentPath !== "/" && isReactAppAuthorized()) {
        setShowAccessDenied(true);
      }
    }
  };

  const loadDocumentInfoData = () => {
    setDocumentInfo({});
    setDocumentStatus("");
    getDocumentInfoByIdAction(dispatch, { documentDataID: props.DocumentDataId, DocumentCategoryID: getDocumentCategoryId() }, (Result: any) => {
      setDocumentInfo(Result);
      setDocumentStatus(Result.Status);
    });
  };

  useEffect(() => {
    validateBreadcrumbsUrls();
  }, [props.currentLocation]);
  

  useEffect(() => {
    validateBreadcrumbsUrls();
    setDocumentInfo({});
    if (props.DocumentDataId !== "" && props.isDocumentInfo) {
      loadDocumentInfoData();
    }
  }, [props.DocumentDataId]);

  useEffect(() => {
    const urlToAcceptInfoPanel = [LEFT_NAVIGATION_PAGE_URL.DASHBOARD, LEFT_NAVIGATION_PAGE_URL.DOCUMENT_UPLOAD, LEFT_NAVIGATION_PAGE_URL.BALLOT, LEFT_NAVIGATION_PAGE_URL.LITIGATION_HOLD];
    if (props.ballotFlag !== 1 && [LEFT_NAVIGATION_PAGE_URL.BALLOT, LEFT_NAVIGATION_PAGE_URL.BALLOT_ITEMS].indexOf(props.currentLocation) > -1) {
      setFlagForBallotNavigation(dispatch, 1);
    }
    if (urlToAcceptInfoPanel.indexOf(props.currentLocation) > -1) {
      handleDocumentInfoClickAction(dispatch, props.DocumentDataId, false, props.displayFileName, props.hasUpdateAccess, documentStatus);
      return;
    }
  }, [props.currentLocation]);

  const sidebarToggle = () => {
    setSidebarClass(!sidebarClass);
  };

  const onClickCloseDocumentInfoButton = () => {
    handleDocumentInfoClickAction(dispatch, props.DocumentDataId, false, props.displayFileName, props.hasUpdateAccess, documentStatus);
  };

  const checkFileAndPassPath = (fileExtension: string): ReactNode => {
    for (let fileItem of fileTypeSVGFormat) {
      if (fileItem.fileEndWith === fileExtension.toLowerCase()) {
        return (
          <svg xmlns="#" viewBox="0 0 384 512">
            <path d={fileItem.path} />
          </svg>
        );
      }
    }
  };

  const showFilesWithIcon = () => {
    const fileNames = getDocumentCategoryId() === categoryIdForDocuments.collaborationAreaDraft ? [documentInfo.Files] : documentInfo.FilesWithExtension.split(",");
    const splitFileSize = documentInfo.FileSize.split(",");
    const splitAssetIds = documentInfo.AssetIds?.split(",");
    return fileNames.map((item: any, index: number) => {
      const splitByDot = item.split(".");
      const fileExtension = splitByDot[splitByDot.length - 1].trim();
      return (
        <>
          <a
            href="javascript:void(0)"
            key={`render-support-file${documentInfo.DocumentDataId}${index}`}
            onClick={() => {
              getDownloadDocumentFileUrlAction(dispatch, { DocumentAssetId: splitAssetIds[index] }, (data: DownloadDocumentFileResponse) => {
                downloadFile(data);
              });
            }}
          >
            {checkFileAndPassPath(fileExtension)}
            {item}
          </a>
          <span className={(fileExtension.length === 3 || fileExtension.length === 4) && item.includes(".") ? "file-size-txt" : "collabration-file-size-txt"}>{splitFileSize[index] && splitFileSize[index].trim()}</span>
        </>
      );
    });
  };

  const onClickShowMoreButton = (): void => {
    setShowMore(!showMore);
  };

  const getClassNameForStatus = (documentInfo: any) => {
    if (documentStatus !== "" && documentStatus === meetingStatusList[1].text) {
      return "inactive-status";
    } else if (documentStatus !== "" && documentStatus === meetingStatusList[0].text) {
      return "active-status";
    } else if (documentStatus === "" && !isEmpty(documentInfo) && documentInfo.Status === meetingStatusList[1].text) {
      return "inactive-status";
    } else if (documentStatus === "" && !isEmpty(documentInfo) && documentInfo.Status === meetingStatusList[0].text) {
      return "active-status";
    }
  };

  const handleDatadogUser = (userInfo: any) => {
		if (userInfo) {
			datadogLogs.setUser({
				UserId: userInfo.userId,
				Email: userInfo.email
			});
		} else {
			datadogLogs.clearUser();
		}
	  }

  const onClickChangeStatusOfDocument = (committeeStatusValue: string, username: string): void => {
    if ([categoryIdForDocuments.allDocuments, categoryIdForDocuments.agenda, categoryIdForDocuments.minutes, categoryIdForDocuments.committeesDocuments, categoryIdForDocuments.voteAttachment].indexOf(getDocumentCategoryId()) > -1 && committeeStatusValue !== documentStatus) {
      let DocumentStatusId = null;
      props.documentStatusByCategory.map((item: any) => {
        if (item.Status === committeeStatusValue) {
          DocumentStatusId = item.DocumentStatusId;
        }
      });
      setDocumentStatus(committeeStatusValue);
      handleDocumentInfoClickAction(dispatch, props.DocumentDataId, true, props.displayFileName, props.hasUpdateAccess, committeeStatusValue);
      const updateDocumentRequestData: UpdateDocumentRequestData = {
        DocumentDataId: documentInfo.DocumentDataId,
        DocumentStatusId,
        DocumentCategoryId: documentInfo.DocumentCategoryId,
        CreatedBy: username,
        displaySuccessMessage: `The status of document '${props.displayFileName}' has been successfully changed from ${committeeStatusValue === meetingStatusList[0].text ? "Inactive" : "Active"} to ${committeeStatusValue}.`,
      };
      if (getDocumentCategoryId() === categoryIdForDocuments.agenda || getDocumentCategoryId() === categoryIdForDocuments.minutes) {
        updateDocumentRequestData.GroupIdentifier = documentInfo.Committee;
        updateDocumentRequestData.MeetingDate = getFormattedDate(documentInfo.Dates, "YYYY-MM-DD");
      }
      updateDocumentInfoAction(dispatch, updateDocumentRequestData, (isRecordUpdated: boolean) => {
        if (isRecordUpdated) {
          loadDocumentInfoData();
        }
      });
    }
  };

  const userDetail = cacheManager.getItem("userDetail") && JSON.parse(cacheManager.getItem("userDetail"));
  handleDatadogUser(userDetail);
  const isUserAuthorized = isReactAppAuthorized();
  const childOne = (
    <div className="card astm-card">
      <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordionExample">
        <div className="card-body">
          <ul className="section-info-wrapper">
            <li>
              <label className="name">Name</label>
              <div className="doc-info">{!isEmpty(documentInfo) && showFilesWithIcon()}</div>
            </li>
            {getDocumentCategoryId() === categoryIdForDocuments.committeesDocuments && (
              <li>
                <label className="name">Display name</label>
                <div className="doc-info">
                  {documentInfo?.Files && showMore ? documentInfo?.Files : documentInfo?.Files && `${documentInfo?.Files?.substring(0, 50)}`}
                  <button className={`btn show-more-txt ${showMore ? "pl-1" : ""}`} onClick={() => onClickShowMoreButton()}>
                    {documentInfo?.Files && documentInfo?.Files.length > 50 && (showMore ? "Show less" : "... Show more")}
                  </button>
                </div>
              </li>
            )}
            {getDocumentCategoryId() === categoryIdForDocuments.collaborationAreaDraft && (
              <li>
                <label className="name">Draft name</label>
                <div className="doc-info">{documentInfo?.FilesWithExtension}</div>
              </li>
            )}
            {getDocumentCategoryId() === categoryIdForDocuments.collaborationAreaDraft && (
              <li>
                <label className="name">Collaboration ID</label>
                <div className="doc-info">{documentInfo?.DisplayId}</div>
              </li>
            )}
            {documentInfo?.OriginalFiles && (
              <li>
                <label className="name">Original name</label>
                <div className="doc-info">
                  <span>{documentInfo?.OriginalFiles}</span>
                </div>
              </li>
            )}
            <li>
              <label className="name">Committee</label>
              <div className="doc-info">
                <span>{documentInfo?.Committee}</span>
              </div>
            </li>
            {[categoryIdForDocuments.voteAttachment, categoryIdForDocuments.closingReports].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Letter ballot</label>
                <div className="doc-info">
                  <span>{documentInfo?.LetterBallot}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Standard</label>
                <div className="doc-info">
                  <span>{documentInfo.StandardDesignation}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotQueue, categoryIdForDocuments.collaborationAreaDraft].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Work item number</label>
                <div className="doc-info">
                  <span>{getDocumentCategoryId() === categoryIdForDocuments.collaborationAreaDraft ? documentInfo?.WorkItem : documentInfo.WorkItemNumber}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Ballot action</label>
                {!isEmpty(documentInfo) && (
                  <div className={`doc-info`}>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" data-testid="statusCheck" id="dropdown-basic" className="btn astm-btn astm-link dropdown-toggle m-0 p-0">
                        <span className="default-status">{!isEmpty(documentInfo) && documentInfo.BallotAction}</span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                )}
              </li>
            )}
            {[categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Ballot level</label>
                {!isEmpty(documentInfo) && (
                  <div className={`doc-info`}>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" data-testid="statusCheck" id="dropdown-basic" className="btn astm-btn astm-link dropdown-toggle m-0 p-0">
                        <span className="default-status">{!isEmpty(documentInfo) && documentInfo.BallotLevel}</span>
                      </Dropdown.Toggle>
                    </Dropdown>
                  </div>
                )}
              </li>
            )}
            {[categoryIdForDocuments.voteAttachment, categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">{categoryIdForDocuments.ballotQueue ? "Item number" : "Item Number"}</label>
                <div className="doc-info">
                  <span>{documentInfo?.ItemNumber}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.voteAttachment].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Account number</label>
                <div className="doc-info">
                  <span>{documentInfo?.AccountNumber}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotItems].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Letter ballot</label>
                <div className="doc-info">
                  <span>{documentInfo.LetterBallot}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.closingReports, categoryIdForDocuments.ballotItems].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Year</label>
                <div className="doc-info">
                  <span>{getDocumentCategoryId() === categoryIdForDocuments.ballotItems ? documentInfo.DocumentYear : documentInfo?.Year}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.allDocuments, categoryIdForDocuments.agenda, categoryIdForDocuments.minutes, categoryIdForDocuments.committeesDocuments].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">{getDocumentCategoryId() === categoryIdForDocuments.committeesDocuments ? "Expiry date" : "Meeting date"}</label>
                <div className="doc-info">
                  <span>{documentInfo.Dates && getFormattedDate(documentInfo?.Dates)}</span>
                </div>
              </li>
            )}
            <li>
              <label className="name">Modified date</label>
              <div className="doc-info">
                <span>{documentInfo?.ModifiedDate && utcDateTimeSecondToLocalDateTime(documentInfo?.ModifiedDate, "MM/DD/YYYY")}</span>
              </div>
            </li>
            {[categoryIdForDocuments.closingReports].indexOf(getDocumentCategoryId()) === -1 && (
              <li>
                <label className="name">Creation date</label>
                <div className="doc-info">
                  <span>{documentInfo.CreatedDate && utcDateTimeSecondToLocalDateTime(documentInfo?.CreatedDate, "MM/DD/YYYY")}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.closingReports].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Closing date</label>
                <div className="doc-info">
                  <span>{documentInfo?.ClosingDate && getFormattedDate(documentInfo?.ClosingDate)}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.closingReports, categoryIdForDocuments.ballotItems, categoryIdForDocuments.collaborationAreaDraft].indexOf(getDocumentCategoryId()) === -1 && (
              <li>
                <label className="name">Status</label>
                {!isEmpty(documentInfo) && (
                  <div className={`doc-info ${!props.hasUpdateAccess && "remove-active"}`}>
                    <Dropdown>
                      <Dropdown.Toggle variant="success" data-testid="statusCheck" id="dropdown-basic" className="btn astm-btn astm-link dropdown-toggle m-0 p-0">
                        <span className={getClassNameForStatus(documentInfo)}>{documentStatus !== "" ? documentStatus : !isEmpty(documentInfo) && documentInfo.Status}</span>
                      </Dropdown.Toggle>

                      {props.hasUpdateAccess && [categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) === -1 && (
                        <Dropdown.Menu>
                          {meetingStatusList.map((meetingItem, index) => {
                            return (
                              <Dropdown.Item key={`meetingitemValue${index}`} onClick={() => onClickChangeStatusOfDocument(meetingItem.text, userDetail.userName)} data-testid="statusCheckItem">
                                <span className={meetingItem.text === meetingStatusList[0].text ? "active-status" : "inactive-status"}>{meetingItem.text}</span>
                              </Dropdown.Item>
                            );
                          })}
                        </Dropdown.Menu>
                      )}
                    </Dropdown>
                  </div>
                )}
              </li>
            )}
            {[categoryIdForDocuments.ballotItems].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Status</label>
                <div className="doc-info">
                  {documentInfo.BallotStatus === BallotStatus.Prepublish ? (
                    <button type="button" className="btn astm-btn badge astm-badge astm-badge-light">
                      {documentInfo.BallotStatus}
                    </button>
                  ) : documentInfo.BallotStatus === BallotStatus.Publish ? (
                    <button type="button" className="btn astm-btn badge astm-badge bg-dark">
                      {documentInfo.BallotStatus}&nbsp;<i className="far fa-check publish-ic"></i>
                    </button>
                  ) : (
                    <button type="button" className="btn astm-btn badge astm-badge bg-danger">
                      {documentInfo.BallotStatus}
                    </button>
                  )}
                  {documentInfo.IsReplaced && (
                    <CustomTooltip
                      title="This ballot item was updated. Please navigate to this ballot folder to republish."
                      placement="top"
                      className="indicator-info"
                      HTMLElement={
                        <span className="indicator-infodoc-icon ml8">
                          <svg xmlns="http://www.w3.org/2000/svg" width="15.9999972px" height="14.2222224px" viewBox="0 0 15.9999972 14.2222224" version="1.1">
                            <g id="Create-Zip-for-Ballot-Items-UX---CHI-1866" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                              <g id="alert-(new)/Button/4-warning" transform="translate(-16, -14.8)" fill="#7E6417">
                                <g id="icon/24x24/exclamation-triangle-amber" transform="translate(16, 14.8)">
                                  <path
                                    d="M6.90964317,5.68625014 L7.09264317,8.79736125 C7.10300428,8.97355569 7.24892095,9.11111125 7.42539317,9.11111125 L8.57455983,9.11111125 C8.75104541,9.11111125 8.89694131,8.97354198 8.90730983,8.79736125 L9.09030983,5.68625014 C9.10155983,5.49477791 8.94933761,5.33333347 8.75755983,5.33333347 L7.24236539,5.33333347 C7.05061539,5.33333347 6.89839317,5.49477791 6.90964317,5.68625014 L6.90964317,5.68625014 Z M9.16667095,10.6666668 C9.16667095,11.3110001 8.64433761,11.8333335 8.00000428,11.8333335 C7.35567095,11.8333335 6.83333761,11.3110001 6.83333761,10.6666668 C6.83333761,10.0223335 7.35567095,9.50000014 8.00000428,9.50000014 C8.64433761,9.50000014 9.16667095,10.0223335 9.16667095,10.6666668 Z M9.15492095,0.666250137 C8.64289317,-0.221277641 7.35805983,-0.222888752 6.84508761,0.666250137 L0.179920945,12.2225835 C-0.331801277,13.1096112 0.308837612,14.2222224 1.33483761,14.2222224 L14.6650043,14.2222224 C15.6890321,14.2222224 16.3326432,13.1113057 15.8199209,12.2225835 L9.15492095,0.666250137 Z M1.47753206,12.6389446 L7.85564317,1.58355569 C7.91978206,1.47238903 8.0802265,1.47238903 8.14436539,1.58355569 L14.5224765,12.6389168 C14.5865876,12.7500279 14.5063932,12.8888613 14.3781154,12.8888613 L1.62189317,12.8888613 C1.49364317,12.8888613 1.41344872,12.7500557 1.47753206,12.6389446 L1.47753206,12.6389446 Z"
                                    id="exclamation-triangle"
                                  />
                                </g>
                              </g>
                            </g>
                          </svg>
                        </span>
                      }
                    />
                  )}
                  {/* <span className="badge astm-badge astm-badge-light">{documentInfo.BallotStatus}</span> */}
                </div>
              </li>
            )}
            {categoryIdForDocuments.collaborationAreaDraft !== getDocumentCategoryId() && (
              <li>
                <label className="name">Category</label>
                <div className="doc-info">
                  <span className="badge astm-badge astm-badge-light">{documentInfo?.Category}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotItems].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Item number</label>
                <div className="doc-info">
                  <span>{documentInfo.IsTerm ? "Terms" : documentInfo.ItemNumber}</span>
                </div>
              </li>
            )}
            {getDocumentCategoryId() === categoryIdForDocuments.committeesDocuments && (
              <li>
                <label className="name">Sequence number</label>
                <div className="doc-info">
                  <span className="badge astm-badge astm-badge-light">{documentInfo?.SequenceNumber}</span>
                </div>
              </li>
            )}
            {[categoryIdForDocuments.ballotQueue].indexOf(getDocumentCategoryId()) > -1 && (
              <li>
                <label className="name">Item type</label>
                <div className={`doc-info ${!isEmpty(documentInfo.ItemType) && "flyover-item"}`}>
                  <span>{documentInfo.ItemType}</span>
                </div>
              </li>
            )}
            {categoryIdForDocuments.collaborationAreaDraft !== getDocumentCategoryId() && (
              <li>
                <label className="name">Uploaded by</label>
                <div className="doc-info">
                  <span className="blue-color">
                    {documentInfo.CreatedBy} on {utcDateTimeSecondToLocalDateTime(documentInfo?.CreatedDate, "MM/DD/YYYY")} at {timeLocal(documentInfo?.CreatedDate)}
                  </span>
                </div>
              </li>
            )}
            {categoryIdForDocuments.collaborationAreaDraft !== getDocumentCategoryId() && (
              <li>
                <label className="name">Modified by</label>
                <div className="doc-info">
                  <span className="blue-color">
                    {documentInfo.ModifiedBy?.split("@")[0]} on {utcDateTimeSecondToLocalDateTime(documentInfo?.ModifiedDate, "MM/DD/YYYY")} at {timeLocal(documentInfo?.ModifiedDate)}
                  </span>
                </div>
              </li>
            )}
            {/* commented due to future development
             */}
          </ul>
        </div>
      </div>
    </div>
  );
  const childTwo = (
    <div className="card astm-card">
      <div id="collapseTwo" className="collapse d-block" aria-labelledby="headingTwo" data-parent="#accordionExample">
        <div className="card-body doc-log-wrapper">
          <ul>
            <li>
              <p className="astm-type-body year-info">Feb 2023</p>
              <p className="astm-type-body mt16">
                <strong>You</strong> replaced this document on 02/14/2023, 10:22 am
              </p>
            </li>
            <li>
              <p className="astm-type-body year-info">Feb 2023</p>
              <p className="astm-type-body mt16">
                <strong>You</strong> replaced this document on 02/14/2023, 10:22 am
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {isUserAuthorized ? (
        <div className="homePage" data-testid="layout_id">
          <HeaderMenu {...props} userDetail={userDetail} />
          <Breadcrumb></Breadcrumb>
          <div className="container">
            <div id="middle" className={`middle-container ${sidebarClass || props.isDocumentInfo ? "remove-sidebar" : ""}`}>
              <LeftMenu sidebarToggle={sidebarToggle} isDocumentInfo={props.isDocumentInfo} />
              {/* RightSetionWrappr start =============*/}
              <div className={`rightSectionWrap ${props.isDocumentInfo ? "adjust-rightSectionWrap" : ""}`}>
                <div ref={rightRef} className={`custom-scrollbar lazyloding-scroll ${history && history.pathname === "/ballot" && props.ballotFlag !== 2 ? "remove-scroll" : ""} ${props.litigationModal ? "litigationModalBox" : ""}`} id="scroll-bar" onScroll={handleScroll}>
                  {isAuth && <Navigation defaultUrl={undefined} isUserAuthorized={isUserAuthorized} isLoginByAzureAD={undefined} userDetail={userDetail} history={undefined}></Navigation>}
                  {!isAuth && showAccessDenied && <AccessDenied />}
                  {props.showNotFoundPage && <InvalidUrl />}
                </div>
              </div>

              {props.isDocumentInfo && (
                <div className={`document-info-sec ${props.isDocumentInfo ? "adjust-documentInfoSec" : ""}`}>
                  <div className="custom-scrollbar" id="scroll-bar">
                    <DocumentInfo show={true} title="Document Information" renderChildInfo={childOne} closeDocumentInfoSection={onClickCloseDocumentInfoButton} />
                    {/* Commented due to future development
                     <DocumentInfo
                      show={false}
                      title="Document Log"
                      renderChildInfo={childTwo}
                      closeDocumentInfoSection={onClickCloseDocumentInfoButton}
                    /> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* <Breadcrumb></Breadcrumb>
                    <div className="ui container scrollData">
                    <Breadcrumb/>
                        {showNotFoundPage ? <PageNotFound /> : (
                            <section className="mainWrapperRight">
                                {isAuthorizedPage ? this.props.children : (<UnAuthorizeAccess />)}
                            </section>
                        )}
                    </div> */}
          {/* To load the chunk before the use */}
          {/* <ExportExcel /> */}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Layout;
